import React from "react";
import { SEO, MarkdownContent, Accordion } from "@bluefin/components";
import { Grid, Segment, Icon } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class PoliciesFaqsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.kjandkompany.com/policies-faqs/"}
        />
        <Grid
          className={"custom-page-policies custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"uoixnovoxhbvxyva"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"tgqtrozghmlkwloz"}
              style={{ padding: 16 }}
              width={11}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "a75192cd-7069-4be3-8e67-25c852ab94bd",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"hilwgtkzunkrqhzk"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
            columns={2}
          >
            <Grid.Column
              className={"korlifbisfejyspy"}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "be4918d8-00c4-4345-8648-21ec5578bdd4",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Accordion
                  className={""}
                  style={{}}
                  styled={true}
                  exclusive={true}
                  defaultActiveIndexes={[]}
                >
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "a629bf65-a461-4afb-bba0-9a6ea2139bd3",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "2dadf142-f75b-4e2d-8d37-8dbcd87d7bac",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "e1118dc7-74ac-439e-af4e-ab6fcef3414a",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "d6d1a608-b9f4-4857-9ce0-89cdd6755d4e",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "c6c53b1d-7dad-4b37-9c1b-1d162772517c",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "10d42664-69a1-41ce-8a5e-cc100a1c3e46",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "cb376713-4e3a-4d98-9ebe-e8a6b7411598",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "6202a9b2-51c4-4bfd-b4b3-75d5abbff728",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "c5589139-ce65-4f2e-9556-7f8c72e9f7d3",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "c3dab57d-9de7-423a-bef9-1f030888444b",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "1a7af012-4a2e-4862-854d-372feadafd50",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "28836bc8-9dcf-4f6c-8ccd-eadab3019663",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                </Accordion>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"kfikpavxpldkzdhg"}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "ae6f28c7-c9c8-41a2-9ace-484f59ac4a0d",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Accordion
                  className={""}
                  style={{}}
                  styled={true}
                  exclusive={true}
                  defaultActiveIndexes={[]}
                >
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "30a365ce-e691-4f91-9fcd-3bf18bdde8ce",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "ba10d6eb-c782-4d6f-a9f4-33c588f51404",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "6822bcb1-7a28-4832-83b0-601c3fbde63c",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "06c3c2e6-fd99-4c2d-95d5-ee37c1d59bf8",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "6ec18fd2-c622-4a5c-8de3-fa21c6e95265",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "dc2ed055-d7e3-42ee-9d97-ec95c505faed",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "e38fd384-3090-4df2-ad26-f0273db39702",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "276d0760-9a4f-460d-b96b-b8f5d4be45ad",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "21a2d8e7-23de-4faa-b2ef-08f6829e6747",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "fc20d9eb-68b2-4be3-a55d-b7fab34eea01",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "79ca417c-bccf-4252-bb19-ba7c237b3b3a",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "1b385b45-200f-4470-9fc2-c601a584bf0f",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "fde018ab-9c24-469c-903e-6d66e43ceffa",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "79b3c762-bec3-43b1-a389-7147260813f5",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                </Accordion>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 29445 }) {
      title
      seoTitle
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
